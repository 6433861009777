<template>
  <div class="flex flex-col">
  <div class="flex flex-col items-start">
    <h2>{{ $t('SupportCard.header') }}</h2>
    <button @click="openTicket"
      class="text-white bg-blue-600 hover:bg-blue-700 rounded-md focus:outline-none w-60 c-p-6 mt-2 py-2 px-4 shadow-lg transition duration-300">
      {{ $t('SupportCard.openTicket') }}
    </button>
    <button @click="openBuySupportModal"
      class="text-white bg-green-600 hover:bg-green-700 rounded-md focus:outline-none w-60 c-p-6 mt-2 py-2 px-4 shadow-lg transition duration-300">
      {{ $t('SupportCard.buySupportHours') }}
    </button>
  </div>
    <!--<div class="mt-1">
        <b-card>
            <div class="flex flex-col">
                <div class="block relative overflow-x-scroll md:overflow-x-hidden">
                    <table class="table w-full relative mb-7">
                        <thead class="">
                            <tr>
                                <th class="text-left whitespace-nowrap p-1 md:p-0"><span class="flex justify-between items-center text-xs text-gray-800">Opened by</span></th>
                                <th class="text-left whitespace-nowrap p-1 md:p-0"><span class="text-xs text-gray-800">Opened on</span></th>
                                <th class="text-left whitespace-nowrap p-1 md:p-0"><span class="text-xs text-gray-800">Subject</span></th>
                                <th class="text-left whitespace-nowrap p-1 md:p-0"><span class="text-xs text-gray-800">Status</span></th>
                                <th class="text-left whitespace-nowrap p-1 md:p-0"><span class="text-xs text-gray-800">Action</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="i in 4" :key="i" class='items-center'>
                                <td>omething</td>
                                <td>omething</td>
                                <td>omething</td>
                                <td>omething</td>
                                <td>omething</td>
                            </tr> 
                        </tbody>
                    </table>-->
          <!-- <span class="flex justify-center" v-if="loading">
              <svg class="animate-spin mr-2 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
          </span> -->
        <!--</div>
        </div>
        </b-card>
        </div>-->
  </div>
</template>

<script>
import bus from '@/bus';

export default {
  name: 'SupportPage',
  data() {
    return {
      loading: true
    }
  },
  methods: {
    openTicket() {
      bus.$emit('open-support')
    }, 
    openBuySupportModal() {
      bus.$emit('open-buy-support');
    },
  },
  mounted() {
    this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', false)
  }
};
</script>

<style scoped>
.table {
  background-color: #ffff !important;
  border: 1px solid #dee2e6 !important;
}

.table th {
  padding: .3rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table tbody, td {
  padding-top: .9rem;
  padding-bottom: .9rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6 !important;
}
</style>
